import { getHost } from '../url'

// bbox
export const spiConfigSaveApi = data => ({
  server: 'beamformers',
  method: 'PUT',
  url: '/api/beamformers/spi/save/config',
  data,
  isAutoDemo: true,
})

// bbox
export const spiConfigImportApi = data => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/spi/import/config',
  data,
  isAutoDemo: true,
})

// bbox
export const spiConfigExportApi = () => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/spi/export/config',
  timeout: 5000,
})

// ris
export const risCommonImportApi = data => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/ris/common/import/channel',
  data,
  isAutoDemo: true,
})
export const risCommonExportChannelApi = () => ({
  server: 'beamformers',
  method: 'POST',
  url: '/api/beamformers/ris/common/export/channel',
  timeout: 5000,
})
