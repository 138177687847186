import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import OverlayMask from 'src/containers/operating/device/beamformers/bbox/DeviceController/BeamSteering/OverlayMask'
import NoAntenna from 'src/containers/operating/device/beamformers/bbox/DeviceController/BeamSteering/NoAntenna'

import BeamSteeringModuleForBBox from 'src/components/composite/beamformers/BeamSteeringModule/ForBBox'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  bbox_steering_thetaPhiChange_watcher,
  bbox_steering_gainChange_watcher,
  bbox_steering_3DCameraChange_watcher,
} from 'src/redux/actions/beamformers/bboxAction'

import {
  change3DDirection,
  setShowManual3DInstructionMask,
} from 'src/redux/slices/uiControl/beamformers/bbox'
import useGetUIControl from 'src/hooks/useGetUIControl'

const BeamSteering = () => {
  const dispatch = useDispatch()

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // sn theta phi...
  const { current } = useGetCurrentDeviceData()
  const { deviceControl } = current.data

  const sn = current?.sn
  const deviceType = current.data.info.deviceType
  const currentControlMode = deviceControl?.common.currentControlMode
  const currentRfMode = deviceControl?.common.rfMode

  const reduxTheta = deviceControl.steering[currentRfMode]?.theta
  const reduxPhi = deviceControl.steering[currentRfMode]?.phi

  const currentAntenna = deviceControl?.common.currentAntenna
  const lstAntennaData = deviceControl?.common.lstAntennaData
  const thetaMax = lstAntennaData.filter(e => e.name === currentAntenna)[0]
    ?.thetaMax

  const { beamCurrentGain, beamGainMin, beamGainMax } =
    deviceControl.steering?.[currentRfMode]

  const [theta, setTheta] = useState(reduxTheta)
  const [phi, setPhi] = useState(reduxPhi)

  // ui control
  const { single: s_uiControl } = useGetUIControl(sn)
  const deviceDirection = s_uiControl?.deviceControl.deviceDirection
  const beam3DCameraArgs = s_uiControl?.deviceControl.beam3DCameraArgs

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  useEffect(() => {
    setTheta(reduxTheta)
    setPhi(reduxPhi)
  }, [reduxTheta, reduxPhi])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleDrag = ({ resultTheta, resultPhi }) => {
    setTheta(resultTheta)
    setPhi(resultPhi)
  }
  const handleDragEnd = () =>
    dispatch(
      bbox_steering_thetaPhiChange_watcher({ sn, theta, phi, isValid: true })
    )

  const handleInputChange = ({ label, value, isValid }) => {
    if (label === 'theta')
      dispatch(
        bbox_steering_thetaPhiChange_watcher({ sn, theta: value, phi, isValid })
      )
    if (label === 'phi')
      dispatch(
        bbox_steering_thetaPhiChange_watcher({ sn, theta, phi: value, isValid })
      )
    if (label === 'gain')
      dispatch(bbox_steering_gainChange_watcher({ sn, value, isValid }))
  }

  const handleCameraChange = ({ position, rotation }) => {
    dispatch(
      bbox_steering_3DCameraChange_watcher({
        sn,
        position,
        rotation,
      })
    )
  }

  const handleDirectionChange = value =>
    dispatch(change3DDirection({ sn, value }))

  const handleHelpButtonClick = () =>
    dispatch(setShowManual3DInstructionMask({ sn, value: true }))

  const handleBoresightClick = () =>
    dispatch(
      bbox_steering_thetaPhiChange_watcher({
        sn,
        theta: 0,
        phi: 0,
        isValid: true,
      })
    )

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      {currentAntenna && (
        <>
          <OverlayMask sn={sn} currentControlMode={currentControlMode} />

          <BeamSteeringModuleForBBox
            {...{
              sn,
              deviceType,
              // value
              theta,
              phi,
              beamCurrentGain,
              thetaMax,
              beamGainMin,
              beamGainMax,

              // 3d control event
              handleDrag,
              handleDragEnd,
              beam3DCameraArgs,
              handleCameraChange,

              // 3d button event
              deviceDirection,
              handleDirectionChange,
              handleHelpButtonClick,
              handleBoresightClick,

              // input event
              handleInputChange,
            }}
          />
        </>
      )}

      {!currentAntenna && <NoAntenna />}
    </div>
  )
}

export default BeamSteering

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------        CSS        ------------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-container] 
                    relative
                    w-full  
                    `
