import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'

import { steeringThetaPhi as handleSteeringThetaPhiResponse } from 'src/redux/sagas/handlers/response/beamformers/ris/steering'

const risApi = {
  RIS_THETA_PHI_CHANGE: {
    emit: {
      server: 'beamformers',
      eventName: 'ris/beam/steering/thetaPhi',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'ris/beam/steering/thetaPhi/response',
      handler: handleSteeringThetaPhiResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default risApi
