import * as R from 'ramda'

export const removeComma = value => String(value).replace(/,/g, '')

//* negativeSwitch
// 不管在哪按 "-" 皆可切換負號
// 1. 判斷有多少個負號
// 2. 一個負號就拔掉全部負號後，在頭加上負號
// 3. 兩個負號就拔掉全部負號
// 4. 沒負號直接 return value
export const negativeSwitch = value => {
  const negativeQuantity = R.count(
    x => x,
    R.filter(item => item === '-')(value)
  )
  const singleNegative = negativeQuantity === 1
  const multiNegative = negativeQuantity === 2

  if (multiNegative)
    return R.pipe(
      R.filter(x => x !== '-'),
      R.join('')
    )(value)

  if (singleNegative)
    return R.pipe(
      R.filter(x => x !== '-'),
      R.insert(0, '-'),
      R.join('')
    )(value)

  return value
}

//* fixLeadingZero
// 忘了參考哪個 library 的去頭的零
// 邏輯只是單純的判斷跟組裝而已
export const fixLeadingZero = (value = '') => {
  let newValue = String(value)
  const isNegative = newValue[0] === '-'

  if (isNegative) newValue = newValue.substring(1, newValue.length)

  const hadDecimal = newValue.indexOf('.') >= 0

  const parts = newValue.split('.')

  let beforeDecimal = parts[0]

  // 之所以判斷 hadDecimal 是為了讓空字串可以順利運作
  // 如果 newValue 有小數點 before 一定要有值(強制去左邊多餘的 0)或是 0
  // 這邊就是讓 "." -> "0."
  if (hadDecimal) beforeDecimal = parts[0].replace(/^0+/, '') || '0'

  // 如果 before 不等於 0 強制去左邊多餘的 0
  if (+parts[0]) beforeDecimal = parts[0].replace(/^0+/, '')

  const afterDecimal = parts[1] || ''

  return (
    (isNegative ? '-' : '') +
    beforeDecimal +
    (hadDecimal ? '.' : '') +
    afterDecimal
  )
}
// ip 專用的 fixLeadingZero,
// 因為完全不會有負數跟小數點，所以拔掉其他邏輯
// 但同時需要支援 空字串 跟 0，所以另外判斷
// +value === 0 一定要回傳 value, 不然空字串會掛掉
export const fixLeadingZeroForIp = (value = '') =>
  +value === 0 ? value : value.replace(/^0+/, '')

//* getRegExp
// 因 input type = text,
// 所以在這過濾所有非數字的輸入
// negative = 是否接受負號 (boolean)
// decimalScale = 是否有小數點且支援幾位 (number)
const getRegExp = (negative, decimalScale) => {
  let result = ''
  if (negative) result += `\\-?`
  result += `(?:[0-9])*`
  if (decimalScale) result += `\\.?([0-9]{1,${+decimalScale}})?`
  return result
}

export const regProcess = (negative, decimalScale) => value =>
  R.pipe(R.match(getRegExp(negative, decimalScale)), R.head)(value)

//* rangeLimit
// 如果有設定 min max
// 將在這限制最大最小值
// 但如果 max === 0 將會出問題
// 之所以不用 Math.min Math.max 來限制
// 是因為這 method 會把小數點過度給轉成數字
// const floatNotFinish = value => R.takeLast(value) === '.'

// const minLimit = min => value =>
//   !R.isNil(min) ? String(value < min ? min : value) : value

// const maxLimit = max => value =>
//   !R.isNil(max) ? String(value > max ? max : value) : value

// export const rangeLimit = (min, max) => value => {
//   if (!floatNotFinish(value)) return R.pipe(minLimit(min), maxLimit(max))(value)
//   return value
// }

//* emptyIsZero
export const setEmptyValue = emptyValue => value => value || emptyValue

export const floorProcess = (value, step) => Math.floor(+value / step) * step
export const ceilProcess = (value, step) => Math.ceil(+value / step) * step

export const isRangePass = (value, min, max) => +value >= min && +value <= max

// * 使用輸入框時，需要被接受的例外狀況清單
// * 這些必須被允許 在輸入某些值的「過程」才會順利
// * ex: 目標值: -0.9, 過程: 會是 '-' -> '-0' -> '-0.' -> '-0.9'
export const inputAccessibleExceptionList = ['', '-', '-0', '-0.', '0.']
