import React, { forwardRef, useRef, useEffect } from 'react'

import { useFBX, shaderMaterial, Box, Text } from '@react-three/drei'
import { useSpring, animated } from '@react-spring/three'

import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import glsl from 'babel-plugin-glsl/macro'

import beamFbx from 'src/assets/fbx/beam.fbx'
import {
  cartesianXyzToViewXyz,
  degreeToRadian,
  getCartesianBySpherical,
} from '../tools'

const BeamMaterial = shaderMaterial(
  // uniform
  {
    vColor1: new THREE.Color('lightgreen'),
    vColor2: new THREE.Color('red'),
    vBeamMin: { x: 0, y: 0 },
    vBeamMax: { x: 14, y: 14 },
  },
  // vertex shader
  glsl`
      uniform vec3 vBeamMin;
      uniform vec3 vBeamMax;
      varying vec2 vUv;
  
      void main() {
        vUv = uv;
  
        vUv.y = (position.y - vBeamMin.y) / (vBeamMax.y - vBeamMin.y);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
  // fragment shader
  glsl`
      uniform vec3 vColor1;
      uniform vec3 vColor2;
  
      varying vec2 vUv;
  
      void main() {
        gl_FragColor = vec4(mix(vColor1 ,vColor2, vUv.y), 1.0);
      }
    `
)

extend({ BeamMaterial })

const _totalLength = 17

const ArrowBeam = forwardRef((props, ref) => {
  const meshRef = useRef(null)
  const fbx = useFBX(beamFbx)
  const beamModel = fbx.children[0].children[0]

  const { lookAt, text, theta, phi, power, isActive, onClick } = props

  useEffect(() => {
    if (meshRef.current && lookAt === 'inside') {
      meshRef.current.rotation.set(0, 0, Math.PI)
      meshRef.current.position.set(0, _totalLength, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookAt])

  const [{ scale }] = useSpring(
    () => ({
      from: {
        scale: isActive ? 1 : 1,
      },
      to: {
        scale: isActive ? 1.1 : 1,
      },
      loop: true,
      config: {
        duration: 1000,
      },
    }),
    [isActive]
  )

  return (
    <>
      <group ref={ref} onClick={onClick}>
        <animated.mesh ref={meshRef} {...beamModel} scale={scale}>
          {isActive ? (
            <beamMaterial wireframe={true} />
          ) : (
            <meshPhongMaterial
              wireframe
              transparent
              opacity={0.1}
              color='#ffffff'
            />
          )}
        </animated.mesh>

        <AnimatedLine {...{ lookAt, isActive }} />
      </group>

      <SuggestText {...{ text, theta, phi, power, isActive }} />
    </>
  )
})

export default ArrowBeam

const AnimatedLine = React.memo(({ lookAt, isActive }) => {
  const _beamLength = isActive ? 12 : 11
  const _startOffset = 0.5
  const _shortPercent = 0.2
  const _lineWidth = 0.3

  // line
  // line
  // line
  // _startOffset 是不要從 0 出發，會跟座標重疊導致看不清楚
  const _lineLength =
    lookAt === 'inside' ? _totalLength - _startOffset : _beamLength

  // _startOffset 是不要從 0 出發，會跟座標重疊導致看不清楚
  const i_start_point = -(_lineLength / 2 + _startOffset)
  const o_start_point = _lineLength / 2 - _startOffset

  //_shortPercent 是動畫要縮短的比例
  const i_short_position = [0, i_start_point * _shortPercent, 0]
  const i_long_position = [0, i_start_point, 0]

  const o_short_position = [0, o_start_point * _shortPercent, 0]
  const o_long_position = [0, o_start_point, 0]

  const _short = lookAt === 'inside' ? i_short_position : o_short_position
  const _long = lookAt === 'inside' ? i_long_position : o_long_position

  // group
  // group
  // group
  const groupPosition =
    lookAt === 'inside' ? [0, _lineLength + _startOffset, 0] : [0, 0, 0]
  const groupRotation =
    lookAt === 'inside' ? [0, degreeToRadian(0), 0] : [0, 0, 0]

  const [{ opacity, lineScale, linePosition }] = useSpring(
    () => ({
      from: {
        opacity: 0,
        lineScale: _shortPercent,
        linePosition: _short,
      },
      to: {
        opacity: 1,
        lineScale: 1,
        linePosition: _long,
      },
      loop: true,
      config: {
        duration: 2000,
      },
    }),
    [isActive]
  )

  return (
    <group rotation={groupRotation} position={groupPosition}>
      {/* Line */}
      <AnimatedBox
        scale={lineScale}
        position={linePosition}
        args={[_lineWidth, _lineLength, _lineWidth]}>
        <animated.meshPhongMaterial transparent opacity={opacity} />
      </AnimatedBox>
    </group>
  )
})

const AnimatedBox = animated(Box)

const SuggestText = props => {
  const { text, theta, phi, power, isActive } = props
  const [cX, cY, cZ] = getCartesianBySpherical([
    power,
    degreeToRadian(theta),
    degreeToRadian(phi),
  ])
  const [vx, vy, vz] = cartesianXyzToViewXyz(cX, cY, cZ)

  // theta ball position = [vx, vy, vz]
  const position = [vx, vy + 2, vz]
  const rotation = [degreeToRadian(180), 0, 0]

  if (isActive)
    return (
      <Text color='gray' scale={0.7} position={position} rotation={rotation}>
        {text}
      </Text>
    )
  return <></>
}
