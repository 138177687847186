import React, { useState } from 'react'
import PropTypes from 'prop-types'

import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'
import InputGroup from 'src/components/Input/InputGroup'
import SwitchGroup from 'src/components/Switch/SwitchGroup'

import {
  thetaIcon,
  thetaMin,
  thetaStep,
  thetaUnit,
  thetaDecimalScale,
  phiIcon,
  phiMax,
  getThetaPhiPhaseWarningText,
} from 'src/constants/beamformers'

import {
  getBeamIndex,
  getBeamName,
  isIncident,
  isReflector,
} from 'src/funcs/device/ris'

const InputControl = ({
  deviceType,
  targetBeam,
  thetaI,
  phiI,
  thetaMaxI,
  thetaR,
  phiR,
  thetaMaxR,

  handleInputChange,
  handleInputFocus,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const _getTheta = targetBeam => {
    if (isIncident(targetBeam)) return thetaI[getBeamIndex(targetBeam)]
    if (isReflector(targetBeam)) return thetaR[getBeamIndex(targetBeam)]
  }
  const _getThetaMax = targetBeam => {
    if (isIncident(targetBeam)) return thetaMaxI[getBeamIndex(targetBeam)]
    if (isReflector(targetBeam)) return thetaMaxR[getBeamIndex(targetBeam)]
  }

  // ---- warning status
  const [thetaWarning, setThetaWarning] = useState({
    incident: false,
    reflector: false,
  })

  const iClassProps = index => ({
    isActive: targetBeam === `incident_${index}`,
  })
  const rClassProps = index => ({
    isActive: targetBeam === `reflector_${index}`,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // ======================
  // warning text
  // ======================
  const thetaWarningText = targetBeam =>
    getThetaPhiPhaseWarningText({
      min: thetaMin,
      max: _getThetaMax(targetBeam),
      step: thetaStep,
    })

  // ======================
  // Input props
  // ======================
  const thetaInputProps = targetBeam => ({
    className: 'w-full ',
    size: 'lg',
    icon: thetaIcon,
    unit: thetaUnit,
    step: thetaStep,
    loop: false,
    validMin: thetaMin,
    validMax: _getThetaMax(targetBeam),
    decimalScale: thetaDecimalScale,
    value: String(_getTheta(targetBeam)) || '',
  })

  // ======================
  // OverwriteElement
  // ======================
  const thetaDialogOverwriteElement = targetBeam => (
    <div className={itemBox}>
      <InputGroup
        warning={true}
        disabled={true}
        {...thetaInputProps(targetBeam)}
      />
      <span className={rangeText + ' text-yellow'}>
        Max {_getThetaMax(targetBeam)}
      </span>
    </div>
  )

  // ======================
  // Dialog props
  // ======================
  const thetaDialogProps = targetBeam => ({
    dialogText:
      thetaWarningText(targetBeam)[thetaWarning[getBeamName(targetBeam)]],
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: thetaDialogOverwriteElement(targetBeam),
    warning: thetaWarning[getBeamName(targetBeam)],
    setWarning: result =>
      setThetaWarning(prev => ({
        ...prev,
        [getBeamName(targetBeam)]: result,
      })),
    setFocus: () => handleInputFocus({ targetBeam }),
    changeCallback: ({ value, isValid }) =>
      handleInputChange({ label: 'theta', targetBeam, value, isValid }),
  })

  const handleSwitchChange = ({ label, targetBeam, value }) =>
    handleInputChange({
      label,
      targetBeam,
      value,
      isValid: true,
    })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      {thetaI.map((e, i, a) => (
        <div
          key={`beam steering input incident ${i}`}
          className='flex flex-col gap-y-3'
          onClick={() => handleInputFocus({ targetBeam: `incident_${i}` })}>
          <h6 className={subtitle(iClassProps(i))}>
            Incident {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
          </h6>

          <div className={itemBox(iClassProps(i))}>
            <InputGroupAppendDialog
              {...thetaInputProps(`incident_${i}`)}
              {...thetaDialogProps(`incident_${i}`)}
            />
            <span className={rangeText}>Max {thetaMaxI[i]}</span>
          </div>

          <div className={itemBox(iClassProps(i))}>
            <SwitchGroup
              className='w-full '
              size='lg'
              value={+phiI[i]}
              icon={phiIcon}
              text1={0}
              text2={180}
              unit='˚'
              onClick={() =>
                handleSwitchChange({
                  label: 'phi',
                  targetBeam: `incident_${i}`,
                  value: +phiI[0] === 0 ? 180 : 0,
                })
              }
            />

            <span className={rangeText}>Max {phiMax}</span>
          </div>
        </div>
      ))}

      {thetaR.map((e, i, a) => (
        <div
          key={`beam steering input reflector ${i}`}
          className='flex flex-col gap-y-3'
          onClick={() => handleInputFocus({ targetBeam: `reflector_${i}` })}>
          <h6 className={subtitle(rClassProps(i))}>
            Reflector {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
          </h6>

          <div className={itemBox(rClassProps(i))}>
            <InputGroupAppendDialog
              {...thetaInputProps(`reflector_${i}`)}
              {...thetaDialogProps(`reflector_${i}`)}
            />
            <span className={rangeText}>Max {thetaMaxR[i]}</span>
          </div>

          <div className={itemBox(rClassProps(i))}>
            <SwitchGroup
              className='w-full '
              size='lg'
              value={+phiR[i]}
              icon={phiIcon}
              text1={0}
              text2={180}
              unit='˚'
              onClick={() =>
                handleSwitchChange({
                  label: 'phi',
                  targetBeam: `reflector_${i}`,
                  value: +phiR[0] === 0 ? 180 : 0,
                })
              }
            />
            <span className={rangeText}>Max {phiMax}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    flex
                    gap-x-4 
                    `

const subtitle = ({ isActive, isOff }) =>
  `${!isOff && isActive ? 'text-teal' : 'text-light-5'} 
                                font-sm font-bold text-center duration-500`
const itemBox = ({ isActive, isOff }) => `
                                ${
                                  !isOff && isActive
                                    ? 'opacity-100 drop-shadow-md'
                                    : 'opacity-50 drop-shadow-2xl'
                                } 
                                flex flex-col w-full 
                                duration-500
                                `
const rangeText = `block pl-3 text-xs text-white/50 font-normal mt-1`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  phi: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  handleInputChange: PropTypes.func,
}
