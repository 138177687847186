import { createAction } from '@reduxjs/toolkit'

export const ris_steering_thetaPhiChange_watcher = createAction(
  'deviceControl/ris_steering_thetaPhiChange_watcher'
)

export const ris_steering_3DCameraChange_watcher = createAction(
  'deviceControl/ris_steering_3DCameraChange_watcher'
)

export const ris_common_afterImport_watcher = createAction(
  'deviceControl/ris_common_afterImport_watcher'
)
export const ris_common_exportClick_watcher = createAction(
  'deviceControl/ris_common_exportClick_watcher'
)
