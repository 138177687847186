import React, { useState } from 'react'

import InputControl from 'src/components/composite/beamformers/BeamSteeringModule/ForRIS/InputControl'
import Beam3DButtons from 'src/components/composite/beamformers/BeamSteeringModule/ForRIS/Beam3DButtons'

import Beam3DControlForRIS from 'src/components/composite/beamformers/Beam3DControl/ForRIS'

import ErrorBoundaryOfThree from 'src/containers/ErrorBoundary/Three'
import { getBeamIndex, isIncident } from 'src/funcs/device/ris'

const BeamSteeringModule = props => {
  const {
    sn,
    isDontRender3D,
    deviceType,
    targetBeam,
    handleTargetBeamChange,
    // value
    thetaI,
    thetaR,
    phiI,
    phiR,
    thetaMaxI,
    thetaMaxR,

    // 3d control event
    handleDrag,
    handleDragEnd,
    beam3DCameraArgs,
    handleCameraChange,

    // 3d button event
    deviceDirection,
    handleDirectionChange,
    handleHelpButtonClick,
    handleBoresightClick,

    // input event
    handleInputChange,
    handleInputFocus,
  } = props

  // 按 reset 鏡頭的按鈕會設成 true, 100ms 後自動設回 false
  const [isCameraReset, setIsCameraReset] = useState(false)

  const isIncidentBoresightDisabled =
    +thetaI[getBeamIndex(targetBeam)] === 0 &&
    +phiI[getBeamIndex(targetBeam)] === 0
  const isReflectorBoresightDisabled =
    +thetaR[getBeamIndex(targetBeam)] === 0 &&
    +phiR[getBeamIndex(targetBeam)] === 0

  const boresightDisabled = isIncident(targetBeam)
    ? isIncidentBoresightDisabled
    : isReflectorBoresightDisabled

  const handleCameraReset = value => setIsCameraReset(value)
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='relative w-full h-[460px]'>
        <ErrorBoundaryOfThree>
          {!isDontRender3D && (
            <Beam3DControlForRIS
              {...{
                sn,
                deviceType,
                targetBeam,
                handleTargetBeamChange,
                thetaI,
                thetaR,
                phiI,
                phiR,
                thetaMaxI,
                thetaMaxR,
                handleDrag,
                handleDragEnd,
                beam3DCameraArgs,
                handleCameraChange,
                isCameraReset,
                handleCameraReset,
                deviceDirection,
              }}
            />
          )}
          <Beam3DButtons
            {...{
              deviceDirection,
              handleDirectionChange,
              handleCameraReset,
              handleHelpButtonClick,
              handleBoresightClick,
              boresightDisabled,
            }}
          />
        </ErrorBoundaryOfThree>
      </div>

      <InputControl
        {...{
          deviceType,
          targetBeam,
          thetaI,
          thetaR,
          phiI,
          phiR,
          thetaMaxI,
          thetaMaxR,
          handleInputChange,
          handleInputFocus,
        }}
      />
    </div>
  )
}

export default BeamSteeringModule

const container = `bg-light-4/10 
                    flex flex-col 
                    gap-y-4 
                    rounded-lg 
                    px-5 md:px-5 xl:px-4
                    pb-10
                    `
