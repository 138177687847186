import Theta from 'src/assets/svg/unit/Theta'
import Phi from 'src/assets/svg/unit/Phi'
import Gain from 'src/assets/svg/unit/Gain'
import Phase from 'src/assets/svg/unit/Phase'

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------                General               -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const cameraPosition = {
  close: [],
  mid: [16, 10, -16],
  far: [22, 16, -22],
}

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------              BBox - Antenna          -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const antennaNameTimestampFormat = `YYYYMMDDkkmmssSSZZ`

export const offsetMin = 0
export const offsetMax = 355

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------           BBox - Beam Steering       -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
// ----- input 規格
// 不設計成 object 或 class 是怕確認都沒確認就直接使用某個欄位會出事
// (thetaMax 會隨著天線而變動)
// (gainMin, gainMax 會隨著選 TX RX 而變動)
export const thetaIcon = <Theta />
export const thetaUnit = '˚'
export const thetaMin = 0
export const thetaStep = 1
export const thetaDecimalScale = 0

export const phiIcon = <Phi />
export const phiUnit = '˚'
export const phiMin = 0
export const phiMax = 359
export const phiStep = 1
export const phiDecimalScale = 0

export const gainIcon = <Gain />
export const gainStep = 0.5
export const gainUnit = 'dB'
export const gainDecimalScale = 1

export const phaseIcon = <Phase />
export const phaseUnit = '˚'
export const phaseMin = 0
export const phaseMax = 355
export const phaseStep = 5
export const phaseDecimalScale = 0

export const commonGainStep = 0.5

// ----- input 輸入非法值跳出的訊息
export const getCommonGainWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min} 
    The system will use the minimum value.`,

  WARNING_MAX: `The maximum value is ${max}. 
    The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. 
  Each gain step is ${step}.`,
})

export const getThetaPhiPhaseWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min} 
      The system will use the minimum value.`,

  WARNING_MAX: `The maximum value is ${max}. 
      The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. Multiples of ${step} is required.`,
})

export const getGainWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min}. 
      The system will use the minimum value.`,

  WARNING_MAX: `The maximum value is ${max}. 
      The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. Each gain step is ${step}.`,
})

export const getCHGainWarningText = ({ min, max, step }) => ({
  WARNING_MIN: `The minimum value is ${min} 
    Please adjust Common Gain for lower value.`,

  WARNING_MAX: `The maximum value is ${max}. 
    The system will use the maximum value.`,

  WARNING_STEP: `Value not allowed. Each gain step is ${step}.`,
})

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------      BBox -  Beam Config Editor      -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const descriptionLengthMax = 50

//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
//* -----------------                  RIS                 -----------------
//* ----------------- ------------------ ----------------- -----------------
//* ----------------- ------------------ ----------------- -----------------
export const risPower = { incident: 17, reflector: 12 }
// export const risPower = { incident: 17, reflector: 7.5 }
